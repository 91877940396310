@font-face {
    font-family: 'gilroyextrabold';
    src: url('../src/fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
         url('../src/fonts/gilroy-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Rubik';
    src: url('../src/fonts/Rubik-Regular.woff') format('woff2'),
         url('../src/fonts/Rubik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}